<template>
    <v-dialog v-model="itemDialog" max-width="900px" style="z-index: 2000 !important;"
        persistent
    >
        <v-card style="position: relative;">
            <v-card-title class="light--text"
                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
            >
                <span ref="formTitle" class="headline">{{ formTitle }}</span>

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on"
                            class="pa-0 mr-2"
                            min-width="48px"
                            text dark
                            @click="resetFields()"
                        >
                            <v-icon color="light">mdi-restart</v-icon>
                        </v-btn>
                    </template>
                    <span>Limpar campos</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on"
                            class="pa-0"
                            @click="close"
                            min-width="48px"
                            text dark
                        >
                            <v-icon color="light">mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-card-title>

            <v-card-text style="background: white; z-index: 2000 !important;">
                <v-container style="background: white; z-index: 2000 !important;">
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <template v-for="field in fields">
                                <v-text-field v-if="field.type === 'text' || field.type === 'number'"
                                    :type="field.type"
                                    :key="field.name"
                                    :label="field.required ? `${field.label} *` : field.label"
                                    v-model="field.value"
                                />

                                <v-autocomplete v-if="field.type === 'combo'"
                                    :key="field.name"
                                    v-model="field.value"
                                    :label="field.required ? `${field.label} *` : field.label"
                                    :items="field.items"
                                    item-value="text"
                                    return-object
                                    :clearable="!field.required"
                                    :loading="field.loading"
                                />

                                <v-menu v-if="field.type === 'date'"
                                    :key="field.name"
                                    :ref="`menu${field.ref}`"
                                    v-model="field.show"
                                    :close-on-content-click="false"
                                    :return-value.sync="field.value"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    style="z-index: 1500 !important;"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="formattedDate(field.value)"
                                            :label="field.label"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                            clearable
                                            style="min-width: 300px !important; z-index: 1000 !important;"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker color="primary"
                                        v-model="field.value"
                                        no-title
                                        scrollable
                                        style="z-index: 1500 !important;"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="field.show = false"
                                        >
                                            Cancelar
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="selectedDate(field)"
                                        >
                                            Confirmar
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                <v-divider class="mx-4"></v-divider>
                <v-card-actions class="px-6 py-6">
                    <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="close"
                        class="mr-4"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="save"
                        :disabled="emptyRequired"
                        :loading="saving"
                    >
                        Salvar
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
    props: {
        itemDialog: { type: Boolean, required: true },
        event: { type: Object, required: true },
        selectedRow: { type: Object, required: false, default: () => {} },
        duplicatingItem: { type: Boolean, required: false, default: false }
    },

    data() {
        return {
            saving: false,

            fields: [
                {
                    name: 'id_vendor',
                    label: 'Fornecedor',
                    value: null,
                    required: true,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipVendor, 'vendor' ]
                },
                {
                    name: 'id_planning_group',
                    label: 'Grupo de planejamento',
                    value: null,
                    required: true,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 6
                        }
                    ]
                },
                {
                    name: 'id_purchase_channel',
                    label: 'Canal de compras',
                    value: null,
                    required: true,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipPurchase, 'purchase-channel' ]
                },
                {
                    name: 'purchase_description',
                    label: 'MEMO',
                    value: null,
                    required: false,
                    type: 'text',
                },
                {
                    name: 'id_employee',
                    label: 'Colaborador',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipOrganization, 'employee' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_employee_type',
                            operator: 'IN',
                            value: [3, 4]
                        }
                    ]
                },
                {
                    name: 'contract_value',
                    label: 'Valor do contrato',
                    value: null,
                    required: false,
                    type: 'number',
                },
                {
                    name: 'id_unit_measurement_value',
                    label: 'Unidade de medidade do valor',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipUnit, 'unit-measurement' ]
                },
                {
                    name: 'contract_quantity',
                    label: 'Quantidade do contrato',
                    value: null,
                    required: false,
                    type: 'number',
                },
                {
                    name: 'id_unit_measurement_qty',
                    label: 'Unidade de medidade da quantidade',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipUnit, 'unit-measurement' ]
                },
                {
                    name: 'id_product',
                    label: 'Contrato',
                    value: null,
                    required: true,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipProduct, 'product' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_product_type',
                            operator: '=',
                            value: 2
                        }
                    ]
                },
                {
                    name: 'purchase_item_code',
                    label: 'Código do item',
                    value: null,
                    required: false,
                    type: 'text',
                },
                {
                    name: 'purchase_item_description',
                    label: 'Descrição do item',
                    value: null,
                    required: false,
                    type: 'text',
                },
                {
                    name: 'renewal_month',
                    label: 'Mês de renovação',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                },
                {
                    name: 'id_market_index',
                    label: 'Índice de reajuste',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipUnit, 'market-index' ]
                },
                {
                    name: 'start_date',
                    label: 'Início do contrato',
                    value: null,
                    required: false,
                    type: 'date',
                    show: false,
                    ref: 'start'
                },
                {
                    name: 'end_date',
                    label: 'Término do contrato',
                    value: null,
                    required: false,
                    type: 'date',
                    show: false,
                    ref: 'end'
                },
            ]
        }
    },

    computed: {
        emptyRequired() {
            return !!this.fields.filter(f => f.required && !f.value).length
        },

        formTitle() {
            let formTitle = 'Novo item'
            if (this.selectedRow.id_event && !this.duplicatingItem) {
                formTitle = 'Editar item'
            }
            return formTitle
        }
    },

    async created() {
        if (this.selectedRow.id_event) {
            // edit item

            let promises = []
            this.fields.filter(f => f.type === 'combo' && f.endpoint).forEach(f => {
                promises.push(new Promise((res, rej) => {
                    this.getItems(f)
                    .then(async (result) => {
                        res(result)
                    })
                    .catch((err) => {
                        rej(err)
                    })
                }))
            })

            const res = await Promise.all(promises)
            if (res) {
                for (let key in this.selectedRow) {
                    let index = this.fields.findIndex(f => f.name === key)
                    if (index > -1) {
                        if (this.fields[index].type === 'combo' && this.fields[index].name !== 'renewal_month') {
                            let found = this.fields[index].items.find(i => i.id === this.selectedRow[key])
                            this.fields[index].value = found
                        } else if (this.fields[index].type === 'date') {
                            if (!this.selectedRow[key]) {
                                this.fields[index].value = null
                            } else {
                                let date = this.selectedRow[key]
                                let year = date.slice(6, 10)
                                let month = date.slice(3, 5)
                                let day = date.slice(0, 2)
                                this.fields[index].value = `${year}-${month}-${day}`
                            }
                        } else {
                            this.fields[index].value = this.selectedRow[key]
                        }
                    }
                }
            }
        } else {
            // new item

            this.resetFields()

            this.fields.forEach(f => {
                if (f.type === 'combo' && f.endpoint) {
                    this.getItems(f)
                }
            })
        }

    },

    methods: {
        async getItems(field) {
            field.loading = true

            let filter = { conditions: [] }
            if (field.conditions) {
                filter.conditions = field.conditions
            }

            try {
                const res = await this.$http.post(`${field.endpoint[0]}${field.endpoint[1]}/list-options`, { filter })
                if (res) {
                    field.items = res.data.rows
                    field.loading = false
                }
            } catch (err) {
                this.$fnError(err)
                field.loading = false
            }
        },

        formattedDate(date) {
            return date ? moment.utc(date).format("DD/MM/YYYY") : null
        },

        selectedDate(field) {
            this.$refs[`menu${field.ref}`][0].save(field.value)
        },

        resetFields() {
            this.fields = this.fields.map(f => {
                return {
                    ...f,
                    value: null
                }
            })
        },

        close() {
            this.$emit('closeItemDialog')
        },

        async save() {
            this.saving = true

            let payload = {}

            payload['id_event'] = this.event.id
            payload['id_account_group'] = 144
            if (this.selectedRow.id_event && !this.duplicatingItem) payload['id_purchase_planning'] = this.selectedRow.id_purchase_planning

            this.fields.forEach(f => {
                if (f.type === 'combo') {
                    return payload[f.name] = f.value ? f.value.id : null
                }

                if (f.type === 'text' || f.type === 'number' || f.type === 'date') {
                    return payload[f.name] = f.value
                }
            })

            try {
                const res = await this.$http.post(`${this.$ipPurchasePlanning}purchase-planning/save`, { ...payload })
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.$emit('closeItemDialog')
                    this.$emit('setRefresh')
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
            }
        }
    }
}
</script>

<style>

</style>