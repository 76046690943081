<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <div class="d-flex align-center pt-2 pb-4">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary" >
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <span class="text-h6 font-weight-regular">{{ tableName }}</span>

                <v-spacer></v-spacer>

                <v-tooltip top v-if="selectedRowId !== null && !Array.isArray(selectedRowId) && !isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="duplicateItem()"
                        >
                            <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                    </template>
                    <span>Duplicar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="selectedRowId !== null && !Array.isArray(selectedRowId) && !isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="editItem()"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="selectedRowId !== null && !isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="dialogMassDelete = true"
                        >
                            <v-icon>mdi-delete-sweep</v-icon>
                        </v-btn>
                    </template>
                    <span>Deletar itens selecionados</span>
                </v-tooltip>

                <v-dialog v-model="dialogMassDelete" max-width="600px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline text-center">Tem certeza de que deseja excluir estes itens?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogMassDelete = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="massDeleteItemConfirm" :loading="deleting">Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top v-if="!isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="createItem()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Novo item</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="renew()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-file-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Renovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRenewGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRenewGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="editedRows = [], renew()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="renew(save = true)" :loading="saving">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="refresh()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="editedRows = [], refresh()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(save = true)" :loading="saving">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top v-if="!isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="saveData()"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'secondary' : 'primary'"
                            :disabled="disableActionButtons || readOnly || !unsavedChanges || isShowingFormulas"
                            :loading="saving"
                        >
                            <v-icon> {{ unsavedChanges || isShowingFormulas ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || readOnly || unsavedChanges"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="disableActionButtons || hasMultipleSelection || unsavedChanges || hasEmptyRequiredFilter"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApproval = true"
                            :disabled="disableActionButtons || hasMultipleSelection || unsavedChanges || !selectedPlanningGroup"
                        >
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogApproval" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title v-if="actualLevel !== null">
                                <span v-if="actualLevel.id_level > 0 && actualLevel.id_status !== 4"> Aprovar nível - {{ actualLevel.id_level }} </span>
                                <span v-else-if="actualLevel.id_status === 4"> Todos os níveis aprovados </span>
                                <span v-else> Nenhum nível cadastrado </span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialogApproval = false, justification = ''"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="selectedEvent"
                                        :items="options.find(option => option.column === 'id_event').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedPlanningGroup"
                                        :items="planningGroups"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Grupo de planejamento"
                                        persistent-hint
                                    ></v-select>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-btn class="my-2 mr-2" color="error" @click="approvalSelect(0)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_level === 1 && actualLevel.id_status !== 4">
                                    Não aprovar
                                </v-btn>
                                <v-btn class="my-2" color="success" @click="approvalSelect(1)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_status === 4">
                                    Aprovar
                                </v-btn>
                                <v-flex>
                                    <v-data-table
                                        :headers="headers"
                                        :items="showHistory === true ? approvals : levels"
                                        :loading="loading"
                                        item-key="id_dynamic_report"
                                        sort-by="Usuário"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-card-actions class="px-6 py-6">
                                <v-checkbox
                                    v-model="showHistory"
                                    label="Exibir o Histórico de todas as Aprovações e Reprovações"
                                ></v-checkbox>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-menu
                    bottom
                    offset-y
                    style="z-index: 1000 !important;"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </template>

                    <v-list style="overflow-y: auto;">
                        <v-list-item @click="showFormulas()">
                            <v-list-item-content>
                                <v-list-item-title>{{ isShowingFormulas ? 'Ocultar fórmulas' : 'Exibir fórmulas' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="showStyles()">
                            <v-list-item-content>
                                <v-list-item-title>{{ isShowingStyles ? 'Remover formatação' : 'Exibir formatação' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="secondary" text @click="editedRows = [], closeGrid()" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="closeGrid(save = true)" :loading="saving">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </div>

            <v-divider class="mb-1"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center mb-1" style="height: 24px !important; width: 100%;">
                <v-col class="d-flex justify-start" style="height: 24px !important;">
                    <v-tabs  
                        v-model="tab"
                        height="24px"
                    >
                        <v-tabs-slider :color="'primary'"></v-tabs-slider>

                        <v-tab
                            v-for="item in tabItems"
                            :key="item"
                        >
                            <span class="text-capitalize">{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                </v-col>

                <v-spacer></v-spacer>

                <v-col v-if="readOnly || isShowingFormulas"
                    class="d-flex justify-end"
                    style="height: 24px !important; cursor: pointer !important;"
                >
                    <v-menu offset-y
                        style="z-index: 1000 !important;"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on"
                                class="d-flex justify-end align-start"
                            >
                                <div class="d-flex align-center">
                                    <v-icon small color="secondary" class="mr-2">mdi-alert-decagram</v-icon>
                                    <span class="secondary--text text-caption">Somente consulta</span>
                                </div>
                            </div>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-list-item dense
                                    v-for="(item, i) in readOnlyRules" :key="i"
                                    class="ma-0 pa-0"
                                >
                                    <v-list-item-icon class="mx-0 mr-2 pa-0">
                                        <v-icon color="secondary">mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-caption font-weight-regular">{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-row align="center" no-gutters class="pt-3">

                        <!-- EVENTO -->
                        <v-autocomplete
                            name="event"
                            :label="eventOption.label"
                            :items="eventOption.items"
                            item-value="text"
                            return-object
                            class="pa-0 mr-4"
                            v-model="event"
                            @change="selectEvent()"
                            @click="backupEvent = event"
                            :loading="loadingEvent"
                            style="max-width: 300px !important; z-index: 1000 !important;"
                            :disabled="unsavedChanges"
                        />

                        <v-dialog v-model="dialogEvent" max-width="850px">
                            <v-card>
                                <div class="d-flex justify-center">
                                    <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                                </div>
                                <v-card-actions class="px-6 pb-6">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="closeDialogEvent()" class="mr-4">Cancelar</v-btn>
                                    <v-btn color="secondary" text @click="editedRows = [], selectEvent()" class="mr-4">Continuar sem salvar</v-btn>
                                    <v-btn color="primary" @click="selectEvent(save = true)" :loading="saving">Salvar e continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- GRUPO DE PLANEJAMENTO -->
                        <div @click="setBackupPlanningGroup()">
                            <v-autocomplete
                                v-model="planningGroup"
                                :items="loadingPlanningGroup ? [] : planningGroupOption.items"
                                :label="planningGroupOption.label"
                                multiple
                                :loading="loadingPlanningGroup"
                                class="pa-0 mr-4"
                                style="max-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @blur="confirmPlanningGroupSelection()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingPlanningGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingPlanningGroup">
                                    <template v-if="loadingPlanningGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="planningGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllPlanningGroups()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!planningGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllPlanningGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="planningGroup.length === planningGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="planningGroup.length !== planningGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${planningGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <div class="d-flex justify-start">
                            <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1month"
                                    @click="showAllMonths(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="260px">
                                        <v-select
                                            v-model="year1months"
                                            :items="months"
                                            label="Escolha os meses"
                                            :item-text="item => item.text"
                                            @change="showMonth(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1month = true, showAllMonths(1)"
                                                :disabled="year1months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Tri"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1quarter"
                                    @click="showAllQuarters(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year1quarters"
                                            :items="quarters"
                                            label="Escolha os trimestres"
                                            :item-text="item => item.text"
                                            @change="showQuarter(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1quarters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1quarter = true, showAllQuarters(1)"
                                                :disabled="year1quarters.length === 4"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Sem"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1semester"
                                    @click="showAllSemesters(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year1semesters"
                                            :items="semesters"
                                            label="Escolha os semestres"
                                            :item-text="item => item.text"
                                            @change="showSemester(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1semesters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1semester = true, showAllSemesters(1)"
                                                :disabled="year1semesters.length === 2"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Ano 1"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="startingYear"
                                    @click="showYear(1)"
                                    :disabled="disableActionButtons"
                                />
                            </div>
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <div class="d-flex justify-start">
                            <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2month"
                                    @click="showAllMonths(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2months"
                                            :items="months"
                                            :item-text="item => item.text"
                                            @change="showMonth(2)"
                                            label="Escolha os meses"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2month = true, showAllMonths(2)"
                                                :disabled="year2months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Tri"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2quarter"
                                    @click="showAllQuarters(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2quarters"
                                            :items="quarters"
                                            label="Escolha os trimestres"
                                            :item-text="item => item.text"
                                            @change="showQuarter(2)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2quarters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2quarter = true, showAllQuarters(2)"
                                                :disabled="year2quarters.length === 4"

                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Sem"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2semester"
                                    @click="showAllSemesters(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2semesters"
                                            :items="semesters"
                                            label="Escolha os semestres"
                                            :item-text="item => item.text"
                                            @change="showSemester(2)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2semesters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2semester = true, showAllSemesters(2)"
                                                :disabled="year2semesters.length === 2"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Ano 2"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="laterYear"
                                    @click="showYear(2)"
                                    :disabled="disableActionButtons"
                                />
                            </div>
                        </div>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="pt-3">

                        <!-- DEPARTAMENTO -->
                        <v-col>
                            <v-autocomplete
                                v-model="costCenter"
                                :items="loadingCostCenter ? [] : costCenterOption.items"
                                :label="costCenterOption.label"
                                multiple
                                :loading="loadingCostCenter"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getCostCenters()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingCostCenter ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingCostCenter">
                                    <template v-if="loadingCostCenter">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="costCenterOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCostCenters()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!costCenter.length ? 'gray' : 'primary'">
                                                    {{ selectAllCostCenterIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="costCenter.length === costCenterOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="costCenter.length !== costCenterOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${costCenter.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTA GERENCIAL -->
                        <v-col>
                            <v-autocomplete
                                v-model="accountGroup"
                                :items="loadingAccountGroup ? [] : accountGroupOption.items"
                                :label="accountGroupOption.label"
                                multiple
                                :loading="loadingAccountGroup"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getAccountGroups()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingAccountGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingAccountGroup">
                                    <template v-if="loadingAccountGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="accountGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCostCenters()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!accountGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllAccountGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="accountGroup.length === accountGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="accountGroup.length !== accountGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${accountGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CANAL DE COMPRAS -->
                        <v-col>
                            <v-autocomplete
                                v-model="purchaseChannel"
                                :items="loadingPurchaseChannel ? [] : purchaseChannelOption.items"
                                :label="purchaseChannelOption.label"
                                multiple
                                :loading="loadingPurchaseChannel"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getPurchaseChannels()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingPurchaseChannel ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingPurchaseChannel">
                                    <template v-if="loadingPurchaseChannel">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="purchaseChannelOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllPurchaseChannels()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!purchaseChannel.length ? 'gray' : 'primary'">
                                                    {{ selectAllPurchaseChannelIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="purchaseChannel.length === purchaseChannelOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="purchaseChannel.length !== purchaseChannelOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${purchaseChannel.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- TIPO DE CONTRATO -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesChannel"
                                :items="loadingSalesChannel ? [] : salesChannelOption.items"
                                :label="salesChannelOption.label"
                                multiple
                                :loading="loadingSalesChannel"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesChannels()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingSalesChannel ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesChannel">
                                    <template v-if="loadingSalesChannel">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesChannelOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesChannels()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesChannel.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesChannelIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesChannel.length === salesChannelOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesChannel.length !== salesChannelOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesChannel.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- GERENTE EXECUTIVO -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesForce"
                                :items="loadingSalesForce ? [] : salesForceOption.items"
                                :label="salesForceOption.label"
                                multiple
                                :loading="loadingSalesForce"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesForces()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingSalesForce ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesForce">
                                    <template v-if="loadingSalesForce">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesForceOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesForces()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesForce.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesForceIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesForce.length === salesForceOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesForce.length !== salesForceOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesForce.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- BP -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesForceBP"
                                :items="loadingSalesForceBP ? [] : salesForceBPOption.items"
                                :label="salesForceBPOption.label"
                                multiple
                                :loading="loadingSalesForceBP"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesForceBPs()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingSalesForceBP ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesForceBP">
                                    <template v-if="loadingSalesForceBP">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesForceBPOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesForceBPs()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesForceBP.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesForceBPIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesForceBP.length === salesForceBPOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesForceBP.length !== salesForceBPOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesForceBP.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- GRUPO DE CLIENTES -->
                        <v-col>
                            <v-autocomplete
                                v-model="customerGroup"
                                :items="loadingCustomerGroup ? [] : customerGroupOption.items"
                                :label="customerGroupOption.label"
                                multiple
                                :loading="loadingCustomerGroup"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getCustomerGroups()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingCustomerGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingCustomerGroup">
                                    <template v-if="loadingCustomerGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="customerGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCustomerGroups()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!customerGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllCustomerGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="customerGroup.length === customerGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="customerGroup.length !== customerGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${customerGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- FORNECEDOR -->
                        <v-col>
                            <v-autocomplete
                                v-model="vendor"
                                :items="loadingVendor ? [] : vendorOption.items"
                                :label="vendorOption.label"
                                multiple
                                :loading="loadingVendor"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getVendors()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingVendor ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingVendor">
                                    <template v-if="loadingVendor">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="vendorOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllVendors()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!vendor.length ? 'gray' : 'primary'">
                                                    {{ selectAllVendorIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="vendor.length === vendorOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="vendor.length !== vendorOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${vendor.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTRATO -->
                        <v-col>
                            <v-autocomplete
                                v-model="product"
                                :items="loadingProduct ? [] : productOption.items"
                                :label="productOption.label"
                                multiple
                                :loading="loadingProduct"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                :disabled="unsavedChanges"
                                :no-data-text="loadingProduct ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingProduct">
                                    <template v-if="loadingProduct">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="productOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllProducts()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!product.length ? 'gray' : 'primary'">
                                                    {{ selectAllProductIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="product.length === productOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="product.length !== productOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${product.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- COLABORADOR -->
                        <v-col>
                            <v-autocomplete
                                v-model="employee"
                                :items="loadingEmployee ? [] : employeeOption.items"
                                :label="employeeOption.label"
                                multiple
                                :loading="loadingEmployee"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getEmployees()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingEmployee ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingEmployee">
                                    <template v-if="loadingEmployee">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="employeeOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllEmployees()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!employee.length ? 'gray' : 'primary'">
                                                    {{ selectAllEmployeeIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="employee.length === employeeOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="employee.length !== employeeOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${employee.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- DESCRIÇÃO DO ITEM -->
                        <v-col>
                            <v-autocomplete
                                v-model="purchaseItemCode"
                                :items="loadingPurchaseItemCode ? [] : purchaseItemCodeOption.items"
                                :label="purchaseItemCodeOption.label"
                                multiple
                                :loading="loadingPurchaseItemCode"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getPurchaseItemCodes()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingPurchaseItemCode ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingPurchaseItemCode">
                                    <template v-if="loadingPurchaseItemCode">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="purchaseItemCodeOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllPurchaseItemCodes()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!purchaseItemCode.length ? 'gray' : 'primary'">
                                                    {{ selectAllPurchaseItemCodeIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="purchaseItemCode.length === purchaseItemCodeOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="purchaseItemCode.length !== purchaseItemCodeOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${purchaseItemCode.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- ÍNDICE DE REAJUSTE -->
                        <v-col>
                            <v-autocomplete
                                v-model="marketIndex"
                                :items="loadingMarketIndex ? [] : marketIndexOption.items"
                                :label="marketIndexOption.label"
                                multiple
                                :loading="loadingMarketIndex"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getMarketIndeces()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingMarketIndex ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingMarketIndex">
                                    <template v-if="loadingMarketIndex">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="marketIndexOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllMarketIndeces()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!marketIndex.length ? 'gray' : 'primary'">
                                                    {{ selectAllMarketIndexIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="marketIndex.length === marketIndexOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="marketIndex.length !== marketIndexOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${marketIndex.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- MÊS DE RENOVAÇÃO -->
                        <v-col class="d-flex justify-start">
                            <v-autocomplete
                                name="renewal_month"
                                :label="renewalMonthOption.label"
                                :items="renewalMonthOption.items"
                                item-value="text"
                                return-object
                                class="pa-0 mr-4"
                                v-model="renewalMonth"
                                @click="backupRenewalMonth = renewalMonth"
                                :loading="!options.find(option => option.column === 'renewal_month').items.length"
                                clearable
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                :disabled="unsavedChanges"
                            />
                        </v-col>

                        <!-- INICIO DO CONTRATO -->
                        <v-col class="d-flex justify-start">
                            <v-menu
                                ref="menuStart"
                                v-model="menuStart"
                                :close-on-content-click="false"
                                :return-value.sync="startDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                style="z-index: 1500 !important;"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="formattedStartDate"
                                        :label="startDateOption.label"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        clearable
                                        @click:clear="clearSelectedStartDate()"
                                        class="pa-0 mr-4"
                                        style="min-width: 300px !important;"
                                        :disabled="unsavedChanges"
                                    ></v-text-field>
                                </template>
                                <v-date-picker color="primary"
                                    v-model="startDate"
                                    no-title
                                    scrollable
                                    style="z-index: 1500 !important;"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menuStart = false"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="selectStartDate()"
                                    >
                                        Confirmar
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <!-- TÉRMINO DO CONTRATO -->
                        <v-col class="d-flex justify-start">
                            <v-menu
                                ref="menuEnd"
                                v-model="menuEnd"
                                :close-on-content-click="false"
                                :return-value.sync="endDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                style="z-index: 1500 !important;"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :value="formattedEndDate"
                                        :label="endDateOption.label"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        clearable
                                        @click:clear="clearSelectedEndDate()"
                                        class="pa-0 mr-4"
                                        style="min-width: 300px !important;"
                                        :disabled="unsavedChanges"
                                    ></v-text-field>
                                </template>
                                <v-date-picker color="primary"
                                    v-model="endDate"
                                    no-title
                                    scrollable
                                    style="z-index: 1500 !important;"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menuEnd = false"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="selectEndDate()"
                                    >
                                        Confirmar
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <!-- VIGÊNCIA DO CONTRATO -->
                        <v-col>
                            <v-autocomplete
                                v-model="frequency"
                                :items="loadingFrequency ? [] : frequencyOption.items"
                                :label="frequencyOption.label"
                                multiple
                                :loading="loadingFrequency"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getFrequencies()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingFrequency ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingFrequency">
                                    <template v-if="loadingFrequency">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="frequencyOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllFrequencies()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!frequency.length ? 'gray' : 'primary'">
                                                    {{ selectAllFrequencyIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="frequency.length === frequencyOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="frequency.length !== frequencyOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${frequency.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- UNIDADE DE MEDIDA DO VALOR -->
                        <!-- <v-col>
                            <v-autocomplete
                                v-model="unitValue"
                                :items="loadingUnitValue ? [] : unitValueOption.items"
                                :label="unitValueOption.label"
                                multiple
                                :loading="loadingUnitValue"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getUnitValues()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingUnitValue ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingUnitValue">
                                    <template v-if="loadingUnitValue">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="unitValueOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllUnitValues()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!unitValue.length ? 'gray' : 'primary'">
                                                    {{ selectAllUnitValueIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="unitValue.length === unitValueOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="unitValue.length !== unitValueOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${unitValue.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col> -->

                        <!-- UNIDADE DE MEDIDA DA QUANTIDADE -->
                        <!-- <v-col>
                            <v-autocomplete
                                v-model="unitQty"
                                :items="loadingUnitQty ? [] : unitQtyOption.items"
                                :label="unitQtyOption.label"
                                multiple
                                :loading="loadingUnitQty"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getUnitQtys()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingUnitQty ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingUnitQty">
                                    <template v-if="loadingUnitQty">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="unitQtyOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllUnitQtys()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!unitQty.length ? 'gray' : 'primary'">
                                                    {{ selectAllUnitQtyIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="unitQty.length === unitQtyOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="unitQty.length !== unitQtyOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${unitQty.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col> -->
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2 pb-3">
                        <v-col class="d-flex justify-start" cols="2"
                            @click="handleSetAllHiddenColumns()"
                            style="cursor: pointer"
                        >
                            <v-icon class="mr-2" :color="setAllHiddenColumnsColor">{{ setAllHiddenColumnsIcon }}</v-icon>
                            <span class="text-subtitle-2">{{ setAllHiddenColumnsText }}</span>
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="costCenterOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCostCenter"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <!-- <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="AccountGroupOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showAccountGroup"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col> -->

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="purchaseChannelOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPurchaseChannel"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesChannelOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesChannel"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesForceOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesForce"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesForceBPOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesForceBP"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="customerGroupOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCustomerGroup"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="vendorOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showVendor"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="MEMO"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPurchaseDescription"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="productOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showProduct"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="employeeOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showEmployee"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="purchaseItemCodeOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPurchaseItemCode"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="marketIndexOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showMarketIndex"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="renewalMonthOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showRenewalMonth"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="startDateOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showStartDate"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="endDateOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showEndDate"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="frequencyOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showFrequency"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Unidade de medida do valor"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showUnitValue"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Unidade de medida da quantidade"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showUnitQty"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <!-- <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Valor do contrato"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showContractValue"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col> -->

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Quantidade contratada"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showContractQuantity"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else :height="windowSize.y - 64 - 192" class="rounded-b px-4">
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="objectData"
                :settings="settings"
                :columns="columns"
                :colHeaders="colHeaders"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :manualColumnResize="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                fixedColumnsLeft="0"
                width="100%"
                height="100%"
                :columnHeaderHeight="50"
                :rowHeaderWidth="50"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <CommentsMenu v-if="drawer"
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="{
                id_event: event ? event.id : null,
                id_planning_group: planningGroup.length ? planningGroup[0].id : null,
            }"
        />

        <ZnapStylesDialog v-if="dialogStyles"
            :dialogStyles="dialogStyles"
            :editedRowStyles="editedRowStyles"
            @closeDialogStyles="dialogStyles = false"
            @saveEditedRowStyle="saveEditedRowStyle($event)"
        />

        <v-dialog v-model="dialogSolver" max-width="900px"
            style="z-index: 5000 !important;"
        >
            <v-card>
                <v-card-title class="headline neutral">
                    <span class="headline">Atingir meta</span>
                </v-card-title>
                <v-card-text>
                    <ZnapSolverDialog 
                        :solver="solverProps"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <ZnapPurchasePlanningItemDialog v-if="itemDialog"
            :itemDialog="itemDialog"
            :event="event"
            :selectedRow="selectedRow"
            :duplicatingItem="duplicatingItem"
            @closeItemDialog="closeItemDialog()"
            @setRefresh="loadGrid()"
        />
    </v-layout>
</template>

<script>
import lodash from 'lodash'
import { mapGetters } from 'vuex'
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import moment from 'moment'
import CommentsMenu from '@/components/znap/CommentsMenu.vue'
import ZnapSolverDialog from '@/components/znap/ZnapSolverDialog.vue'
import ZnapStylesDialog from '@/components/znap/ZnapStylesDialog.vue'
import ZnapPurchasePlanningItemDialog from '@/components/znap/ZnapPurchasePlanningItemDialog.vue'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapSolverDialog, ZnapStylesDialog, ZnapPurchasePlanningItemDialog
    },

    props: {
        options: { type: Array, required: true },
    },

    data() {
        return {
            itemDialog: false,
            
            tab: 0,
            tabItems: ['Evento', 'Filtros', 'Exibir'],

            objectData: [],
            settings: {
                formulas: {
                    engine: HyperFormula,
                },
                afterSelection: () => {
                    if (this.hotInstance) {
                        let cellCoords = this.hotInstance.getSelectedLast()

                        if (cellCoords) {
                            let firstRow = cellCoords[0]
                            let lastRow = cellCoords[2]

                            if (firstRow === lastRow) {
                                this.selectedRowId = firstRow
                            } else {
                                this.selectedRowId = [ firstRow, lastRow ]
                            }
                        } else {
                            this.selectedRowId = null
                        }
                    }
                },
                afterChange: (changes) => {
                    if (changes) {
                        let editedChanges = changes.filter(c => c[3] != c[2])
                        editedChanges.forEach(([ row, tableColumn, oldVal, newVal ]) => {
                            let physicalRow = this.hotInstance.toPhysicalRow(row)

                            let optionColumn = tableColumn
                            if (tableColumn === 'vendor_description') optionColumn = 'id_vendor'
                            if (tableColumn === 'employee_name') optionColumn = 'id_employee'
                            if (tableColumn === 'unit_measurement_qty_description') optionColumn = 'id_unit_measurement_qty'
                            if (tableColumn === 'unit_measurement_value_description') optionColumn = 'id_unit_measurement_value'
                            if (tableColumn === 'product_name') optionColumn = 'id_product'
                            if (tableColumn === 'purchase_item_description') optionColumn = 'purchase_item_code'
                            if (tableColumn === 'frequency') optionColumn = 'id_frequency'

                            let option = this.dropdownOptions.find(d => d.column === optionColumn)
                            if (option) {
                                if (tableColumn === 'vendor_description') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['id_vendor'] = null
                                        this.objectData[physicalRow]['cnpj'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['id_vendor'] = foundItem.id
                                        this.objectData[physicalRow]['cnpj'] = foundItem.cnpj
                                    }

                                    this.hotInstance.render()
                                }

                                if (tableColumn === 'employee_name') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['id_employee'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['id_employee'] = foundItem.id
                                    }

                                    this.hotInstance.render()
                                }

                                if (tableColumn === 'unit_measurement_qty_description') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['id_unit_measurement_qty'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['id_unit_measurement_qty'] = foundItem.id
                                    }

                                    this.hotInstance.render()
                                }

                                if (tableColumn === 'unit_measurement_value_description') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['id_unit_measurement_value'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['id_unit_measurement_value'] = foundItem.id
                                    }

                                    this.hotInstance.render()
                                }

                                if (tableColumn === 'product_name') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['id_product'] = null
                                        this.objectData[physicalRow]['product_code'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['id_product'] = foundItem.id
                                        this.objectData[physicalRow]['product_code'] = foundItem.product_code
                                    }

                                    this.hotInstance.render()
                                }

                                if (tableColumn === 'purchase_item_description') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['purchase_item_code'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['purchase_item_code'] = foundItem.id
                                    }

                                    this.hotInstance.render()
                                }

                                if (tableColumn === 'market_index_code') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['id_market_index'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['id_market_index'] = foundItem.id
                                    }

                                    this.hotInstance.render()
                                }

                                if (tableColumn === 'frequency') {
                                    if (!newVal) {
                                        this.objectData[physicalRow]['id_frequency'] = null
                                    } else {
                                        let foundItem = option.items.find(i => i.text === newVal)
                                        this.objectData[physicalRow]['id_frequency'] = foundItem.id
                                    }

                                    this.hotInstance.render()
                                }
                            }

                            if (oldVal === '') {
                                oldVal = null
                                this.objectData[physicalRow][tableColumn] = oldVal
                            }
                            
                            if (!this.editedRows.includes(row)) {
                                this.editedRows.push(row)
                            }
                        })
                    }
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData);
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                cells: (row, col, prop) => {
                    var cellProperties = {}

                    const stylizedRow = this.stylizedRows[row] || { index: -1 }
                    const accountGroupRowIndex = Object.keys(this.objectData[0]).findIndex(k => k === 'account_group_description')

                    if (row === stylizedRow.index) {
                        if (this.isShowingStyles) {
                            if (stylizedRow.className) {
                                if (stylizedRow.className.includes('first-column-only')) {
                                    if (col === accountGroupRowIndex) {
                                        cellProperties.className = stylizedRow.className
                                    } else {
                                        let arrayOfStyles = stylizedRow.className.split(' ')
                                        let borderStyles = arrayOfStyles.filter(s => s.includes('border')).join(' ')
                                        cellProperties.className = borderStyles
                                    }
                                } else {
                                    cellProperties.className = stylizedRow.className
                                }
                            }
                        }

                        if (stylizedRow.readOnly) {
                            cellProperties.readOnly = stylizedRow.readOnly
                        }

                        if (this.readOnlyColumns.includes(col)) {
                            const totalColumns = [
                                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                            ]

                            if (totalColumns.includes(prop)) {
                                cellProperties.className += ` border-sides-1 font-bold`
                            } else {
                                cellProperties.className += ` read-only-column`
                            }
                        }

                        if (stylizedRow.percent) {
                            cellProperties.numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    if (this.isShowingFormulas) {
                        cellProperties.readOnly = false
                    } else {
                        if (this.readOnly) {
                            cellProperties.readOnly = true
                        }
                    }

                    return cellProperties
                },
            },
            columns: [],
            colHeaders: [],
            rowHeaders: [],
            stylizedRows: [],
            hiddenColumns: {},
            hiddenRows: { rows: [], indicators: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
                {
                    name() {
                        return 'Estilizar linha'
                    },
                    hidden() {
                        let hidden = true
                        const cellCoords = this.getSelectedLast()
                        if (cellCoords[1] === -1) {
                            hidden = false
                        }
                        return hidden
                    },
                    callback: () => {
                        this.dialogStyles = true
                        let row = this.hotInstance.getSelectedLast()[2]
                        let id = this.objectData[row].id_account_group
                        let css = this.objectData[row].css

                        this.editedRowStyles = { id, css, row }
                    }
                },
                {
                    name() {
                        return 'Editar'
                    },
                    hidden() {
                        let hidden = false
                        const cellCoords = this.getSelectedLast()
                        const firstRow = cellCoords[0]
                        const lastRow = cellCoords[2]
                        if (firstRow < 1 || firstRow !== lastRow) {
                            hidden = true
                        }
                        return hidden
                    },
                    callback: (key, selection) => {
                        this.selectedRowId = selection[0].start.row
                        // this.newEmployeeAllocationDialog = true
                    }
                }
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            dialogSolver: false,
            solverProps: {
                columnName: null,
                column: null,
                row: null,
                rowProps: null,
                targetRow: null,
                targetValue: null,
                changeRow: null,
            },

            payload: {
                id_event: null,
                id_planning_group: null,
                id_purchase_channel: null,
                id_vendor: null,
                id_product: null,
                id_employee: null,
                id_unit_measurement_value: null,
                id_unit_measurement_qty: null,
                purchase_item_code: null,
                id_market_index: null,
                renewal_month: null,
                end_date: null,
                id_account_group_version: null,
            },

            // ! DADOS DO LOAD
            event: null,
            loadingEvent: true,
            dialogEvent: false,
            backupEvent: null,

            accountGroupVersion: null,

            planningGroup: [],
            loadingPlanningGroup: true,
            backupPlanningGroup: null,

            purchaseChannel: [],
            loadingPurchaseChannel: false,
            showPurchaseChannel: false,

            salesChannel: [],
            loadingSalesChannel: false,
            showSalesChannel: false,

            salesForce: [],
            loadingSalesForce: false,
            showSalesForce: false,

            salesForceBP: [],
            loadingSalesForceBP: false,
            showSalesForceBP: false,

            customerGroup: [],
            loadingCustomerGroup: false,
            showCustomerGroup: false,

            costCenter: [],
            loadingCostCenter: false,
            showCostCenter: false,

            accountGroup: [],
            loadingAccountGroup: false,
            showAccountGroup: false,

            vendor: [],
            loadingVendor: false,
            showVendor: true,

            product: [],
            loadingProduct: true,
            showProduct: true,

            employee: [],
            loadingEmployee: false,
            showEmployee: true,

            purchaseItemCode: [],
            loadingPurchaseItemCode: false,
            showPurchaseItemCode: false,

            marketIndex: [],
            loadingMarketIndex: false,
            showMarketIndex: false,

            showPurchaseDescription: true,
            showProductSomething: true,

            renewalMonth: null,
            showRenewalMonth: true,

            menuStart: false,
            startDate: null,
            showStartDate: true,

            menuEnd: false,
            endDate: null,
            showEndDate: true,

            frequency: [],
            loadingFrequency: false,
            showFrequency: false,

            unitValue: [],
            loadingUnitValue: false,
            showUnitValue: true,

            unitQty: [],
            loadingUnitQty: false,
            showUnitQty: true,

            showContractValue: true,
            showContractQuantity: true,

            selectedRowId: null,
            selectedRow: {},
            duplicatingItem: false,

            dialogCloseGrid: false,
            dialogRenewGrid: false,
            dialogRefreshGrid: false,
            dialogStyles: false,
            dialogMassDelete: false,
            deleting: false,

            disableEdition: false,
            readOnlyRules: [],

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            year1: null,
            year2: null,
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            readOnlyColumns: [],
            isShowingFormulas: false,
            isShowingStyles: true,
            backupColHeaders: [],
            backupReadOnly: null,

            // Unsaved changes
            editedRows: [],

            windowSize: { x: 0, y: 0 },
            loading: true,
            saving: false,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },

            hotInstance: null,

            drawer: false,
            exporting: false,
            importing: false,

            // workflow
            readOnly: false,
            readOnlyApproval: false,
            dialogApproval: false,
            loadingApproval: false,
            justification: '',
            headers: [
                {
                    text: 'Semáforo', value: 'id_status'
                },
                {
                    text: 'Nível', value: 'approval_flow_level_description'
                },
                {
                    text: 'Status', value: 'status'
                },
                {
                    text: 'Usuário', value: 'name'
                },
                {
                    text: 'Data', value: 'date'
                },
                {
                    text: 'Justificativa', value: 'justification'
                }
            ],
            levels: [],
            planningGroups: [],
            selectedPlanningGroup: null,
            selectedEvent: null,
            showHistory: false,
            approvals: null,
            userGroups: [],
            actualLevel: {
                id_level: 0,
                id_group: 0
            },
            disableApprove: true
        }
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },

    destroyed() {
        this.removePreventCloseHandler()
    },

    computed: {
        ...mapGetters('auth', ['getUser', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName
            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon
            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        disableActionButtons() {
            return this.loadingEvent
                || this.loadingPlanningGroup
        },

        unsavedChanges() {
            return this.editedRows.length > 0;
        },

        dropdownOptions() {
            return this.options.filter(o => o.isDropdownOption)
        },

        formattedStartDate() {
            return this.startDate ? moment.utc(this.startDate).format("DD/MM/YYYY") : null
        },

        formattedEndDate() {
            return this.endDate ? moment.utc(this.endDate).format("DD/MM/YYYY") : null
        },

        hasMultipleSelection() {
            return this.planningGroup.length > 1
                || this.purchaseChannel.length > 1
                || this.vendor.length > 1
                || this.product.length > 1
                || this.employee.length > 1
        },

        hasMultipleColumns() {
            return this.showPurchaseChannel
                || this.showSalesChannel
                || this.showSalesForce
                || this.showSalesForceBP
                || this.showCustomerGroup
                || this.showCostCenter
                || this.showAccountGroup
                || this.showVendor
                || this.showPurchaseDescription
                || this.showProduct
                || this.showEmployee
                || this.showPurchaseItemCode
                || this.showMarketIndex
                || this.showRenewalMonth
                || this.showStartDate
                || this.showEndDate
                || this.showFrequency
                || this.showUnitValue
                || this.showUnitQty
                || this.showContractQuantity
        },

        hasEmptyRequiredFilter() {
            return !this.event
        },

        disableLoadGrid() {
            return false
        },

        setAllHiddenColumnsText() {
            return this.hasMultipleColumns ? 'DESMARCAR TODOS' : 'SELECIONAR TODOS'
        },

        setAllHiddenColumnsIcon() {
            if (!this.hasMultipleColumns) {
                return 'mdi-checkbox-blank-outline'
            } else {
                return 'mdi-close-box'
            }
        },

        setAllHiddenColumnsColor() {
            return this.hasMultipleColumns ? 'primary' : ''
        },

        eventOption() {
            return this.options.find(o => o.column === 'id_event')
        },

        planningGroupOption() {
            return this.options.find(o => o.column === 'id_planning_group')
        },

        purchaseChannelOption() {
            return this.options.find(o => o.column === 'id_purchase_channel')
        },

        salesChannelOption() {
            return this.options.find(o => o.column === 'id_sales_channel')
        },

        salesForceOption() {
            return this.options.find(o => o.column === 'id_sales_force')
        },

        salesForceBPOption() {
            return this.options.find(o => o.column === 'id_sales_force_bp')
        },

        customerGroupOption() {
            return this.options.find(o => o.column === 'id_customer_group')
        },

        costCenterOption() {
            return this.options.find(o => o.column === 'id_cost_center')
        },

        accountGroupOption() {
            return this.options.find(o => o.column === 'id_account_group')
        },

        vendorOption() {
            return this.options.find(o => o.column === 'id_vendor')
        },

        productOption() {
            return this.options.find(o => o.column === 'id_product')
        },

        employeeOption() {
            return this.options.find(o => o.column === 'id_employee')
        },

        purchaseItemCodeOption() {
            return this.options.find(o => o.column === 'purchase_item_code')
        },

        marketIndexOption() {
            return this.options.find(o => o.column === 'id_market_index')
        },

        renewalMonthOption() {
            return this.options.find(o => o.column === 'renewal_month')
        },

        startDateOption() {
            return this.options.find(o => o.column === 'start_date')
        },

        endDateOption() {
            return this.options.find(o => o.column === 'end_date')
        },

        frequencyOption() {
            return this.options.find(o => o.column === 'id_frequency')
        },

        unitValueOption() {
            return this.options.find(o => o.column === 'id_unit_measurement_value')
        },

        unitQtyOption() {
            return this.options.find(o => o.column === 'id_unit_measurement_qty')
        },

        selectAllPlanningGroupIcon() {
            let icon = ''

            if (!this.planningGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.planningGroup.length < this.planningGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllPurchaseChannelIcon() {
            let icon = ''

            if (!this.purchaseChannel.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.purchaseChannel.length < this.purchaseChannelOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesChannelIcon() {
            let icon = ''

            if (!this.salesChannel.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesChannel.length < this.salesChannelOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesForceIcon() {
            let icon = ''

            if (!this.salesForce.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesForce.length < this.salesForceOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesForceBPIcon() {
            let icon = ''

            if (!this.salesForceBP.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesForceBP.length < this.salesForceBPOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllCustomerGroupIcon() {
            let icon = ''

            if (!this.customerGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.customerGroup.length < this.customerGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllCostCenterIcon() {
            let icon = ''

            if (!this.costCenter.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.costCenter.length < this.costCenterOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllVendorIcon() {
            let icon = ''

            if (!this.vendor.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.vendor.length < this.vendorOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllProductIcon() {
            let icon = ''

            if (!this.product.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.product.length < this.productOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllEmployeeIcon() {
            let icon = ''

            if (!this.employee.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.employee.length < this.employeeOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllPurchaseItemCodeIcon() {
            let icon = ''

            if (!this.purchaseItemCode.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.purchaseItemCode.length < this.purchaseItemCodeOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllMarketIndexIcon() {
            let icon = ''

            if (!this.marketIndex.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.marketIndex.length < this.marketIndexOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllFrequencyIcon() {
            let icon = ''

            if (!this.frequency.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.frequency.length < this.frequencyOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllUnitValueIcon() {
            let icon = ''

            if (!this.unitValue.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.unitValue.length < this.unitValueOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllUnitQtyIcon() {
            let icon = ''

            if (!this.unitQty.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.unitQty.length < this.unitQtyOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllAccountGroupIcon() {
            let icon = ''

            if (!this.accountGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.accountGroup.length < this.accountGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },
    },

    watch: {
        // readOnly (val) {
        //     console.log(val)
        // },

        event (val) {
            if (val) {
                // this.selectedEvent = val.id
                try {
                    this.$http.post(this.$ipApprovalFlow + 'planning-group' + '/list-options',
                        {
                            filter: {
                                conditions: [
                                    {
                                        AndOr: "AND",
                                        column: "id_planning_group_version",
                                        operator: "=",
                                        value: val.id_planning_group_version
                                    }
                                ]
                            }
                        }
                    ).then((res) => {
                        this.planningGroups = res.data.rows
                    })
                } catch (err) {
                    this.$fnError(err)
                }
            }
        },

        planningGroup (val) {
            if (val[0]){
                // this.selectedPlanningGroup = val[0].id
            } else {
                this.levels = []
                this.approvals = []
                this.selectedPlanningGroup = 0
            }
        },
    },

    beforeMount() {
        this.init()
    },

    methods: {
        async init() {
            this.listUserGroups()

            await this.getEvents()
            if (!this.eventOption.items.length) {
                return this.noEventsFound()
            }

            this.setDateColumns()

            await this.getPlanningGroups()
            if (!this.planningGroupOption.items.length) {
                return this.noPlanningGroupsFound()
            }

            await this.getProducts()
            if (!this.productOption.items.length) {
                return this.noProductsFound()
            }

            await this.getDropdownOptions()

            return this.loadGrid()
        },

        async loadGrid() {
            this.loading = true
            this.hotInstance = null
            this.editedRows = []
            this.selectedRowId = null
            this.selectedRow = {}
            this.duplicatingItem = false

            this.noDataMessage = null
            this.noDataBtn = { path: null, text: null }

            if (this.disableLoadGrid) {
                return this.loading = false
            }

            if (this.planningGroup.length) {
                this.selectedEvent = this.event.id
                this.selectedPlanningGroup = this.planningGroup[0].id
                this.levels = []
                this.approvals = []
                await this.approvalListLevels()
                await this.approvalHistory()
            }

            this.disableEdition = false
            this.readOnlyRules = []
            if (this.hasMultipleSelection || this.hasEmptyRequiredFilter) {
                this.disableEdition = true
            }
            
            this.readOnly = this.disableEdition || this.readOnlyApproval
            if (this.readOnly) this.setReadOnlyRules()

            let payload = this.setPayload()

            try {
                const purchasePlanningRes = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/list', { ...payload })
                if (purchasePlanningRes) {
                    let _colHeaders = []
                    let _columns = []
                    let resColumns = purchasePlanningRes.data.columns
                    this.formulas = purchasePlanningRes.data.formulas

                    purchasePlanningRes.data.headers.forEach((header) => {
                        let headerIndex = parseInt(header.value.slice(5, 7), 10)
                        let columnIndex = resColumns.findIndex(column => column.columnAlias === header.value)
                        if (columnIndex === -1) return

                        let headerText = header.text

                        if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            if (header.value === 'value00') {
                                let values = header.text.split(' ')
                                headerText = `${values[0]} ${values[1] || ''}`
                            }

                            let year = null
                            if (headerIndex > 0 && headerIndex <= 12) {
                                if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                    year = this.year1
                                } else {
                                    year = this.year2
                                }
                                headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            } else if (headerIndex >= 13 && headerIndex <= 24) {
                                if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                    year = this.year2
                                } else {
                                    year = parseInt(this.year2, 10) + 1
                                }
                                headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            }
                        }
                        _colHeaders.push(headerText)

                        let readOnly = false
                        // Aqui modifica readOnly de acordo com id_status
                        this.readOnly ? readOnly = true: readOnly = false

                        if (header.editable === false) {
                            readOnly = true
                        }

                        let type = null
                        let source = null
                        let className = ''
                        let numericFormat = null

                        if (header.options) {
                            let found = this.dropdownOptions.find(o => o.column === header.columnId)
                            
                            if (found) {
                                let itemsText = []

                                if (found.column === 'renewal_month') {
                                    itemsText = found.items
                                } else {
                                    itemsText.push(null)
                                    found.items.forEach(item => {
                                        itemsText.push(item.text)
                                    })
                                }

                                type = 'dropdown'
                                source = itemsText
                            }
                        } else if (header.value === 'contract_quantity') {
                            type = 'numeric'
                            className += ' htRight'
                            numericFormat = {
                                pattern: {
                                    thousandSeparated: true,
                                    negative: "parenthesis",
                                    mantissa: 0,
                                },
                                culture: ptBR.languageCode
                            }
                        } else if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            type = 'numeric'
                            className += ' htRight'
                            numericFormat = {
                                pattern: {
                                    thousandSeparated: true,
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        } else {
                            type = 'text'
                            className += ' htLeft'
                        }

                        let hide = false
                        if (header.hideColumn) {
                            hide = true
                        }

                        const totalColumns = [
                            'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                            'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                        ]

                        if (totalColumns.includes(header.value)) {
                            className += ' border-sides-1 font-bold'
                        }

                        let width = undefined
                        if (header.value === 'vendor_description') {
                            width = '400'
                        } else if (header.value === 'purchase_description') {
                            width = '500'
                        }

                        _columns.push({
                            data: header.value,
                            readOnly,
                            type,
                            className,
                            numericFormat,
                            source,
                            hide,
                            columnLetter: header.columnLetter,
                            width
                        })
                    })
                    this.colHeaders = this.isShowingFormulas ? true : _colHeaders
                    this.columns = _columns

                    this.setHiddenColumns()
                    this.setDateReadOnlyColumns()

                    let _rowHeaders = []
                    let _objectData = []
                    this.stylizedRows = []

                    purchasePlanningRes.data.rows.forEach((row, index) => {
                        _rowHeaders.push('')

                        let rowProps = {
                            index,
                            row,
                            className: '',
                            format: row.format,
                            readOnly: false,
                            percent: false,
                        }

                        let subtotalsQty = Object.entries(row).reduce((acc, cur) => {
                            return cur[1] && typeof cur[1] === 'string' && cur[1].includes('Subtotal -') ? acc + 1 : acc + 0
                        }, 0)

                        if (subtotalsQty > 1) {
                            let counter = 0
                            for (const field in row) {
                                if (row[field] && typeof row[field] === 'string' && row[field].includes('Subtotal -')) {
                                    if (counter > 0) {
                                        row[field] = ''
                                    }

                                    counter += 1
                                }
                            }
                        }

                        if (row.indent) {
                            rowProps.className += ' indentedRow'
                            row['account_group_description'] = ' '.repeat(row.indent) + row['account_group_description']
                        }

                        if (row.format.includes('%')) {
                            rowProps.percent = true
                        }

                        if (row.start_date) {
                            row.start_date = moment(row.start_date).utc().format('DD/MM/YYYY')
                        }

                        if (row.end_date) {
                            row.end_date = moment(row.end_date).utc().format('DD/MM/YYYY')
                        }

                        if (row.contract_value) {
                            row.contract_value = parseFloat(row.contract_value)
                        }

                        if (row.contract_quantity) {
                            row.contract_quantity = parseFloat(row.contract_quantity)
                        }

                        if ((row.data_type === 'INT' || row.data_type === 'DECIMAL')) {
                            for (let i = 0; i <= 24; i++) {
                                if (i < 10) {
                                    // if (!row[`value0${i}`].includes('=')) {
                                        row[`value0${i}`] = parseFloat(row[`value0${i}`], 10) || null
                                    // }
                                } else {
                                    // if (!row[`value${i}`].includes('=')) {
                                        row[`value${i}`] = parseFloat(row[`value${i}`], 10) || null
                                    // }
                                }
                            }
                        }

                        if (row.total > 0) {
                            // rowProps.className += ' formulaRow'

                            let foundFormula = this.formulas.find(formula => formula.system_id === row.system_id)
                            let startIndex = this.formulas.indexOf(foundFormula)
                            let endIndex = this.formulas.indexOf(foundFormula) + 23

                            if (foundFormula) {
                                for (let i = startIndex; i <= endIndex; i++) {
                                    row[this.formulas[i].column] = this.formulas[i].excelFormula
                                }
                            }
                        }

                        if (row.css.length) {
                            rowProps.className += ` ${row.css}`
                        }

                        if (row.subtotal) {
                            rowProps.className += ` font-bold`
                        }

                        if (row.editable != 1) {
                            rowProps.readOnly = true
                        }

                        this.stylizedRows.push(rowProps)

                        _objectData.push(row)
                    })
                    this.rowHeaders = _rowHeaders
                    this.objectData = _objectData

                    if (!this.objectData.length) {
                        this.noDataMessage = 'Não há dados disponíveis'
                    }

                    return this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        setPayload() {
            let filter = {
                conditions: []
            }

            if (this.planningGroup.length > 0 && this.planningGroup.length <= this.planningGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_planning_group',
                    operator: 'IN',
                    value: this.planningGroup.map(i => i.id)
                })
            }

            if (this.purchaseChannel.length === 1 || (this.purchaseChannel.length > 0 && this.purchaseChannel.length < this.purchaseChannelOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_purchase_channel',
                    operator: 'IN',
                    value: this.purchaseChannel.map(i => i.id)
                })
            }

            if (this.salesChannel.length > 0 && this.salesChannel.length < this.salesChannelOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_channel',
                    operator: 'IN',
                    value: this.salesChannel.map(i => i.id)
                })
            }

            if (this.salesForce.length > 0 && this.salesForce.length < this.salesForceOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_force',
                    operator: 'IN',
                    value: this.salesForce.map(i => i.id)
                })
            }

            if (this.salesForceBP.length > 0 && this.salesForceBP.length < this.salesForceBPOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_force_bp',
                    operator: 'IN',
                    value: this.salesForceBP.map(i => i.id)
                })
            }

            if (this.customerGroup.length > 0 && this.customerGroup.length < this.customerGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_customer_group',
                    operator: 'IN',
                    value: this.customerGroup.map(i => i.id)
                })
            }

            if (this.costCenter.length > 0 && this.costCenter.length < this.costCenterOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_cost_center',
                    operator: 'IN',
                    value: this.costCenter.map(i => i.id)
                })
            }

            if (this.accountGroup.length > 0 && this.accountGroup.length < this.accountGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_account_group',
                    operator: 'IN',
                    value: this.accountGroup.map(i => i.id)
                })
            }

            if (this.vendor.length === 1 || (this.vendor.length > 0 && this.vendor.length < this.vendorOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_vendor',
                    operator: 'IN',
                    value: this.vendor.map(i => i.id)
                })
            }

            if (this.product.length === 1 || (this.product.length > 0 && this.product.length < this.productOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_product',
                    operator: 'IN',
                    value: this.product.map(i => i.id)
                })
            }

            if (this.employee.length === 1 || (this.employee.length > 0 && this.employee.length < this.employeeOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_employee',
                    operator: 'IN',
                    value: this.employee.map(i => i.id)
                })
            }
            
            if (this.unitValue.length === 1 || (this.unitValue.length > 0 && this.unitValue.length < this.unitValueOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_unit_measurement_value',
                    operator: 'IN',
                    value: this.unitValue.map(i => i.id)
                })
            }

            if (this.unitQty.length === 1 || (this.unitQty.length > 0 && this.unitQty.length < this.unitQtyOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_unit_measurement_qty',
                    operator: 'IN',
                    value: this.unitQty.map(i => i.id)
                })
            }

            if (this.purchaseItemCode.length === 1 || (this.purchaseItemCode.length > 0 && this.purchaseItemCode.length < this.purchaseItemCodeOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'purchase_item_code',
                    operator: 'IN',
                    value: this.purchaseItemCode.map(i => i.id)
                })
            }

            if (this.marketIndex.length === 1 || (this.marketIndex.length > 0 && this.marketIndex.length < this.marketIndexOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_market_index',
                    operator: 'IN',
                    value: this.marketIndex.map(i => i.id)
                })
            }

            if (this.renewalMonth) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'renewal_month',
                    operator: '=',
                    value: this.renewalMonth
                })
            }

            if (this.startDate) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'start_date',
                    operator: '=',
                    value: this.startDate
                })
            }

            if (this.endDate) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'end_date',
                    operator: '=',
                    value: this.endDate
                })
            }

            if (this.frequency.length === 1 || (this.frequency.length > 0 && this.frequency.length < this.frequencyOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_frequency',
                    operator: 'IN',
                    value: this.frequency.map(i => i.id)
                })
            }

            return {
                id_event: this.event.id,
                id_account_group_version: this.event.id_account_group_version,
                filter,
                showAccountGroup: true,
                showPurchaseChannel: this.showPurchaseChannel,
                showSalesChannel: this.showSalesChannel,
                showSalesForce: this.showSalesForce,
                showSalesForceBp: this.showSalesForceBP,
                showCustomerGroup: this.showCustomerGroup,
                showCostCenter: this.showCostCenter,
                showVendor: this.showVendor,
                showProduct: this.showProduct,
                showEmployee: this.showEmployee,
                showPurchaseDescription: this.showPurchaseDescription,
                showMarketIndex: this.showMarketIndex,
                showPurchaseItem: this.showPurchaseItemCode,
                showRenewalMonth: this.showRenewalMonth,
                showStartDate: this.showStartDate,
                showEndDate: this.showEndDate,
                showFrequency: this.showFrequency,
                showUnitMeasurementValue: this.showUnitValue,
                showUnitMeasurementQty: this.showUnitQty,
                showContractValue: this.showContractValue,
                showContractQuantity: this.showContractQuantity,
            }
        },

        async saveData() {
            this.saving = true

            let payload = { items: [] }

            for (let i = 0; i < this.objectData.length; i++) {
                if (this.editedRows.includes(i)) {
                    let object = {}

                    this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                        if (this.columns[index].data === 'start_date' || this.columns[index].data === 'end_date') {
                            if (field) {
                                let day = field.slice(0, 2)
                                let month = field.slice(3, 5) - 1
                                let year = field.slice(-4)
                                let date = moment(new Date(year, month, day)).format('YYYY-MM-DD')

                                object[this.columns[index].data] = date
                            } else {
                                object[this.columns[index].data] = null
                            }
                        } else {
                            object[this.columns[index].data] = field
                        }
                    })
    
                    payload.items.push(object)
                }
            }

            try {
                const res = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/mass-save', { ...payload })
                if (res) {
                    this.editedRows = []
                    this.$toast.success(res.data.msg)
                    this.saving = false
                    this.loadGrid()
                    return 'success'
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
                return 'error'
            }
        },

        async renew(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogRenewGrid = true
            }

            this.loading = true

            this.dialogRenewGrid = false
            this.hotInstance = null
            this.selectedRowId = null
            this.selectedRow = {}
            this.duplicatingItem = false

            this.eventOption.items = []
            this.event = []
            this.loadingEvent = true

            this.planningGroupOption.items = []
            this.planningGroup = []
            this.loadingPlanningGroup = true

            this.productOption.items = []
            this.product = []
            this.loadingProduct = true

            this.purchaseChannelOption.items = []
            this.purchaseChannel = []

            this.salesChannelOption.items = []
            this.salesChannel = []

            this.salesForceOption.items = []
            this.salesForce = []

            this.salesForceBPOption.items = []
            this.salesForceBP = []

            this.customerGroupOption.items = []
            this.customerGroup = []

            this.costCenterOption.items = []
            this.costCenter = []

            this.accountGroupOption.items = []
            this.accountGroup = []

            this.vendorOption.items = []
            this.vendor = []

            this.employeeOption.items = []
            this.employee = []

            this.purchaseItemCodeOption.items = []
            this.purchaseItemCode = []

            this.marketIndexOption.items = []
            this.marketIndex = []

            this.renewalMonth = null
            this.startDate = null
            this.endDate = null

            this.frequencyOption.items = []
            this.frequency = []

            this.unitValueOption.items = []
            this.unitValue = []

            this.unitQtyOption.items = []
            this.unitQty = []

            return this.init()
        },

        async refresh(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogRefreshGrid = true
            }

            this.loading = true
            this.dialogRefreshGrid = false
            this.hotInstance = null
            this.selectedRowId = null
            this.selectedRow = {}
            this.duplicatingItem = false
            
            await this.getDropdownOptions()
            
            return this.loadGrid()
        },

        async closeGrid(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogCloseGrid = true
            }
            
            return this.$router.push('/')
        },

        setReadOnlyRules() {
            // if (!this.show) {
            //     this.readOnlyRules.push('Exiba a coluna de Nome do projeto')
            // }

            if (this.readOnlyApproval) {
                if (this.actualLevel && this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4) {
                    this.readOnlyRules.push('Nível de aprovação não permite alterações')
                } else {
                    this.readOnlyRules.push('Evento com todos os níveis aprovados')
                }
            }
        },

        async getDropdownOptions() {
            for (const option of this.dropdownOptions) {
                if (!option.items.length) {
                    try {
                        let payload = {
                            filter: {
                                conditions: []
                            }
                        }

                        if (option.conditions?.length) {
                            payload.filter.conditions = option.conditions
                        }
                        
                        let res = await this.$http.post(option.endpoint[0] + `${option.endpoint[1]}/${option.endpoint[2] ? option.endpoint[2] : 'list-options'}`, payload)
                        if (res) {
                            option.items = res.data.rows
                        }
                        
                    } catch (err) {
                        this.$fnError(err)
                    }
                }
            }
        },

        async massDeleteItemConfirm() {
            this.deleting = true

            let items = []
            let payload = {
                delete: {}
            }

            if (this.selectedRowId.length) {
                this.selectedRowId.sort()
                let firstRow = this.selectedRowId[0]
                let lastRow = this.selectedRowId[1]

                for (let i = firstRow; i <= lastRow; i++) {
                    items.push({ id_purchase_planning: this.objectData[i].id_purchase_planning })
                }

                payload.delete = { items }
            } else {
                items.push({ id_purchase_planning: this.objectData[this.selectedRowId].id_purchase_planning })
                payload.delete = { items }
            }

            try {
                const res = await this.$http.post(`${this.$ipPurchasePlanning}purchase-planning/mass-effect`, payload)
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.loadGrid()
                    this.dialogMassDelete = false
                    this.deleting = false
                    this.selectedRowId = null
                    this.selectedRow = {}
                }
            } catch (err) {
                this.$fnError(err)
                this.deleting = false
            }
        },

        async getEvents() {
            this.loadingEvent = true

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_module",
                        operator: "=",
                        value: 6
                    },
                    {
                        AndOr: "AND",
                        column: "id_status",
                        operator: "NOT LIKE",
                        value: 2
                    },
                    {
                        AndOr: "OR",
                        column: "id_planning_function",
                        operator: "=",
                        value: 4
                    },
                ]
            }

            try {
                const eventOption = this.options.find(option => option.column === 'id_event')
                const eventRes = await this.$http.post(`${eventOption.endpoint[0]}${eventOption.endpoint[1]}/${eventOption.endpoint[2] ? eventOption.endpoint[2] : 'list-options'}`, { filter })
                if (eventRes) {
                    eventOption.items = eventRes.data.rows
                    let foundFirst = eventOption.items.find((item, index) => index === 0)
                    this.event = foundFirst
                    this.year1 = this.event.fiscal_year
                    this.yearMonthInputStart = this.event.year_month_input_start
                    this.fiscalYearStartMonth = this.event.fiscal_year_start_month
                    this.startingMonthIndex = this.event.fiscal_year_start_month - 1
                    this.year2 = this.year1 + 1

                    this.loadingEvent = false
                }
            } catch (err) {
                this.loadingEvent = false
                this.$fnError(err)
            }
        },

        closeDialogEvent() {
            this.event = this.backupEvent
            this.dialogEvent = false
        },

        async selectEvent(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogEvent = true  
            }

            this.loading = true
            this.dialogEvent = false

            this.year1 = this.event.fiscal_year
            this.yearMonthInputStart = this.event.year_month_input_start
            this.fiscalYearStartMonth = this.event.fiscal_year_start_month
            this.startingMonthIndex = this.event.fiscal_year_start_month - 1
            this.year2 = this.year1 + 1

            this.setDateColumns()

            this.planningGroupOption.items = []
            this.planningGroup = []
            this.loadingPlanningGroup = true

            this.productOption.items = []
            this.product = []
            this.loadingProduct = true

            await this.getPlanningGroups()
            if (!this.planningGroupOption.items.length) {
                return this.noPlanningGroupsFound()
            }

            await this.getProducts()
            if (!this.productOption.items.length) {
                return this.noProductsFound()
            }

            return this.loadGrid()
        },

        noEventsFound() {
            this.event = null
            this.planningGroup = []
            this.purchaseChannel = []
            this.salesChannel = []
            this.salesForce = []
            this.salesForceBP = []
            this.customerGroup = []
            this.costCenter = []
            this.accountGroup = []
            this.vendor = []
            this.product = []
            this.employee = []

            this.noDataMessage = 'Cadastre um evento para iniciar o planejamento'
            this.noDataBtn.path = '/event'
            this.noDataBtn.text = 'Cadastrar evento'

            this.loading = false
        },

        async getPlanningGroups() {
            this.loadingPlanningGroup = true

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_planning_group_version",
                        operator: "=",
                        value: this.event.id_planning_group_version
                    }
                ]
            }

            try {
                const planningGroupRes = await this.$http.post(`${this.planningGroupOption.endpoint[0]}${this.planningGroupOption.endpoint[1]}/${this.planningGroupOption.endpoint[2] ? this.planningGroupOption.endpoint[2] : 'list-options'}`, { filter })
                if (planningGroupRes) {
                    this.planningGroupOption.items = planningGroupRes.data.rows

                    let foundFirst = this.planningGroupOption.items.find((item, index) => index === 0)
                    this.planningGroup.push(foundFirst)
                    
                    this.loadingPlanningGroup = false
                }
            } catch (err) {
                this.loadingPlanningGroup = false
                this.$fnError(err)
            }
        },

        setAllPlanningGroups() {
            if (!this.planningGroup.length) {
                this.planningGroup = lodash.cloneDeep(this.planningGroupOption.items)
            } else {
                this.planningGroup = []
            }
        },

        setBackupPlanningGroup() {
            this.backupPlanningGroup = lodash.cloneDeep(this.planningGroup)
        },

        async confirmPlanningGroupSelection() {
            if (lodash.isEqual(this.planningGroup, this.backupPlanningGroup)) {
                return
            }

            this.productOption.items = []
            this.product = []
            this.loadingProduct = true

            await this.getProducts()
            if (!this.productOption.items.length) {
                return this.noProductsFound()
            }
        },

        noPlanningGroupsFound() {
            this.planningGroup = []
            this.loadingPlanningGroup = false

            this.product = []
            this.loadingProduct = false

            this.noDataMessage = 'Cadastre um grupo de planejamento'
            this.noDataBtn.path = '/planning-group'
            this.noDataBtn.text = 'Cadastrar grupo de planejamento'

            this.loading = false
        },

        async getPurchaseChannels() {
            this.loadingPurchaseChannel = true

            try {
                const purchaseChannelRes = await this.$http.post(`${this.purchaseChannelOption.endpoint[0]}${this.purchaseChannelOption.endpoint[1]}/${this.purchaseChannelOption.endpoint[2] ? this.purchaseChannelOption.endpoint[2] : 'list-options'}`, { })
                if (purchaseChannelRes) {
                    this.purchaseChannelOption.items = purchaseChannelRes.data.rows

                    // let foundFirst = this.purchaseChannelOption.items.find((item, index) => index === 0)
                    // this.purchaseChannel.push(foundFirst)

                    this.loadingPurchaseChannel = false
                } 
            } catch (err) {
                this.loadingPurchaseChannel = false
                this.$fnError(err)
            }
        },

        setAllPurchaseChannels() {
            if (!this.purchaseChannel.length) {
                this.purchaseChannel = lodash.cloneDeep(this.purchaseChannelOption.items)
            } else {
                this.purchaseChannel = []
            }
        },

        async getSalesChannels() {
            this.loadingSalesChannel = true

            let filter = { conditions: [] }
            filter.conditions.push(
                {
                    AndOr: 'AND',
                    column: 'id_sales_channel_type',
                    operator: '=',
                    value: this.event.id_sales_channel_type
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesChannelRes = await this.$http.post(`${this.salesChannelOption.endpoint[0]}${this.salesChannelOption.endpoint[1]}/${this.salesChannelOption.endpoint[2] ? this.salesChannelOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesChannelRes) {
                    this.salesChannelOption.items = salesChannelRes.data.rows

                    // let foundFirst = this.salesChannelOption.items.find((item, index) => index === 0)
                    // this.salesChannel.push(foundFirst)

                    this.loadingSalesChannel = false
                } 
            } catch (err) {
                this.loadingSalesChannel = false
                this.$fnError(err)
            }
        },

        setAllSalesChannels() {
            if (!this.salesChannel.length) {
                this.salesChannel = lodash.cloneDeep(this.salesChannelOption.items)
            } else {
                this.salesChannel = []
            }
        },

        async getSalesForces() {
            this.loadingSalesForce = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",   
                    column: "id_sales_force_type",
                    operator: "=",
                    value: this.event.id_sales_force_type
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesForceRes = await this.$http.post(`${this.salesForceOption.endpoint[0]}${this.salesForceOption.endpoint[1]}/${this.salesForceOption.endpoint[2] ? this.salesForceOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesForceRes) {
                    this.salesForceOption.items = salesForceRes.data.rows

                    // let foundFirst = this.salesForceOption.items.find((item, index) => index === 0)
                    // this.salesForce.push(foundFirst)

                    this.loadingSalesForce = false
                } 
            } catch (err) {
                this.loadingSalesForce = false
                this.$fnError(err)
            }
        },

        setAllSalesForces() {
            if (!this.salesForce.length) {
                this.salesForce = lodash.cloneDeep(this.salesForceOption.items)
            } else {
                this.salesForce = []
            }
        },

        async getSalesForceBPs() {
            this.loadingSalesForceBP = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",   
                    column: "id_sales_force_type",
                    operator: "=",
                    value: 7
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesForceBPRes = await this.$http.post(`${this.salesForceBPOption.endpoint[0]}${this.salesForceBPOption.endpoint[1]}/${this.salesForceBPOption.endpoint[2] ? this.salesForceBPOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesForceBPRes) {
                    this.salesForceBPOption.items = salesForceBPRes.data.rows

                    // let foundFirst = this.salesForceBPOption.items.find((item, index) => index === 0)
                    // this.salesForceBP.push(foundFirst)

                    this.loadingSalesForceBP = false
                } 
            } catch (err) {
                this.loadingSalesForceBP = false
                this.$fnError(err)
            }
        },

        setAllSalesForceBPs() {
            if (!this.salesForceBP.length) {
                this.salesForceBP = lodash.cloneDeep(this.salesForceBPOption.items)
            } else {
                this.salesForceBP = []
            }
        },

        async getCustomerGroups() {
            this.loadingCustomerGroup = true

            try {
                const customerGroupRes = await this.$http.post(`${this.customerGroupOption.endpoint[0]}${this.customerGroupOption.endpoint[1]}/${this.customerGroupOption.endpoint[2] ? this.customerGroupOption.endpoint[2] : 'list-options'}`, { })
                if (customerGroupRes) {
                    this.customerGroupOption.items = customerGroupRes.data.rows

                    // let foundFirst = this.customerGroupOption.items.find((item, index) => index === 0)
                    // this.customerGroup.push(foundFirst)

                    this.loadingCustomerGroup = false
                } 
            } catch (err) {
                this.loadingCustomerGroup = false
                this.$fnError(err)
            }
        },

        setAllCustomerGroups() {
            if (!this.customerGroup.length) {
                this.customerGroup = lodash.cloneDeep(this.customerGroupOption.items)
            } else {
                this.customerGroup = []
            }
        },

        async getCostCenters() {
            this.loadingCostCenter = true

            let filter = { conditions: [] }
            filter.conditions.push({
                AndOr: 'AND',
                column: 'id_cost_center_type',
                operator: '=',
                value: 3
            })

            try {
                const costCenterRes = await this.$http.post(this.costCenterOption.endpoint[0] + this.costCenterOption.endpoint[1] + '/list-options-purchase-planning', { id_event: this.event.id, filter })
                if (costCenterRes) {
                    this.costCenterOption.items = costCenterRes.data.rows

                    // let foundFirst = this.costCenterOption.items.find((item, index) => index === 0)
                    // this.costCenter.push(foundFirst)

                    this.loadingCostCenter = false
                } 
            } catch (err) {
                this.loadingCostCenter = false
                this.$fnError(err)
            }
        },

        setAllCostCenters() {
            if (!this.costCenter.length) {
                this.costCenter = lodash.cloneDeep(this.costCenterOption.items)
            } else {
                this.costCenter = []
            }
        },

        async getAccountGroups() {
            this.loadingAccountGroup = true

            let filter = { conditions: [] }
            filter.conditions.push({
                AndOr: 'AND',
                column: 'id_account_group_version',
                operator: '=',
                value: this.event.id_account_group_version
            })

            try {
                const accountGroupRes = await this.$http.post(this.accountGroupOption.endpoint[0] + this.accountGroupOption.endpoint[1] + '/list-options', { filter })
                if (accountGroupRes) {
                    this.accountGroupOption.items = accountGroupRes.data.rows

                    // let foundFirst = this.costCenterOption.items.find((item, index) => index === 0)
                    // this.costCenter.push(foundFirst)

                    this.loadingAccountGroup = false
                } 
            } catch (err) {
                this.loadingAccountGroup = false
                this.$fnError(err)
            }
        },

        setAllAccountGroups() {
            if (!this.accountGroup.length) {
                this.accountGroup = lodash.cloneDeep(this.accountGroupOption.items)
            } else {
                this.accountGroup = []
            }
        },

        async getVendors() {
            this.loadingVendor = true

            let filter = { conditions: [] }
                
            try {
                const vendorRes = await this.$http.post(this.vendorOption.endpoint[0] + this.vendorOption.endpoint[1] + '/list-options-purchase-planning', { id_event: this.event.id, filter })
                if (vendorRes) {
                    this.vendorOption.items = vendorRes.data.rows

                    // let foundFirst = this.vendorOption.items.find((item, index) => index === 0)
                    // this.vendor.push(foundFirst)
                    this.loadingVendor = false
                }
            } catch (err) {
                this.loadingVendor = false
                this.$fnError(err)
            }
        },

        setAllVendors() {
            if (!this.vendor.length) {
                this.vendor = lodash.cloneDeep(this.vendorOption.items)
            } else {
                this.vendor = []
            }
        },

        async getProducts() {
            this.loadingProduct = true

            let filter = { conditions: [] }
            // let filter = {
            //     conditions: [
            //         {
            //             AndOr: "AND",
            //             column: "fiscal_year",
            //             operator: "=",
            //             value: this.year1
            //         },
            //         {
            //             AndOr: "AND",
            //             column: "id_event_type",
            //             operator: "=",
            //             value: this.event.id_event_type
            //         }
            //     ]
            // }

            // if (this.planningGroup.length && this.planningGroup.length < this.planningGroupOption.items.length) {
            //     filter.conditions.push({
            //         AndOr: "AND",
            //         column: "id_planning_group",
            //         operator: "IN",
            //         value: this.planningGroup.map(i => i.id)
            //     })
            // }

            try {
                const productRes = await this.$http.post(this.productOption.endpoint[0] + this.productOption.endpoint[1] + '/list-options-purchase-planning', { id_event: this.event.id, filter })
                if (productRes) {
                    this.productOption.items = productRes.data.rows

                    // let foundFirst = this.productOption.items.find((item, index) => index === 0)
                    // this.product.push(foundFirst)

                    this.loadingProduct = false
                }
            } catch (err) {
                this.loadingProduct = false
                this.$fnError(err)
            }
        },

        setAllProducts() {
            if (!this.product.length) {
                this.product = lodash.cloneDeep(this.productOption.items)
            } else {
                this.product = []
            }
        },

        noProductsFound() {
            this.product = []
            this.loadingProduct = false
        },

        async getEmployees() {
            this.loadingEmployee = true

            let filter = { conditions: [] }
                
            try {
                const employeeRes = await this.$http.post(this.employeeOption.endpoint[0] + this.employeeOption.endpoint[1] + '/list-options-purchase-planning', { id_event: this.event.id, filter })
                if (employeeRes) {
                    this.employeeOption.items = employeeRes.data.rows

                    // let foundFirst = this.employeeOption.items.find((item, index) => index === 0)
                    // this.employee.push(foundFirst)
                    this.loadingEmployee = false
                }
            } catch (err) {
                this.loadingEmployee = false
                this.$fnError(err)
            }
        },

        setAllEmployees() {
            if (!this.employee.length) {
                this.employee = lodash.cloneDeep(this.employeeOption.items)
            } else {
                this.employee = []
            }
        },

        async getUnitValues() {
            this.loadingUnitValue = true

            let filter = { conditions: [] }
            let column = 'id_unit_measurement_value'
            
            let payload = {
                id_event: this.event.id,
                filter,
                column
            }

            try {
                const unitValueRes = await this.$http.post(this.unitValueOption.endpoint[0] + this.unitValueOption.endpoint[1] + '/list-options-purchase-planning', { payload })
                if (unitValueRes) {
                    this.unitValueOption.items = unitValueRes.data.rows

                    // let foundFirst = this.unitValueOption.items.find((item, index) => index === 0)
                    // this.unitValue.push(foundFirst)
                    this.loadingUnitValue = false
                }
            } catch (err) {
                this.loadingUnitValue = false
                this.$fnError(err)
            }
        },

        setAllUnitValues() {
            if (!this.unitValue.length) {
                this.unitValue = lodash.cloneDeep(this.unitValueOption.items)
            } else {
                this.unitValue = []
            }
        },

        async getUnitQtys() {
            this.loadingUnitQty = true

            let filter = { conditions: [] }
            let column = 'id_unit_measurement_qty'

            let payload = {
                id_event: this.event.id,
                filter,
                column
            }
                
            try {
                const unitQtyRes = await this.$http.post(this.unitQtyOption.endpoint[0] + this.unitQtyOption.endpoint[1] + '/list-options-purchase-planning', { payload })
                if (unitQtyRes) {
                    this.unitQtyOption.items = unitQtyRes.data.rows

                    // let foundFirst = this.unitQtyOption.items.find((item, index) => index === 0)
                    // this.unitQty.push(foundFirst)
                    this.loadingUnitQty = false
                }
            } catch (err) {
                this.loadingUnitQty = false
                this.$fnError(err)
            }
        },

        setAllUnitQtys() {
            if (!this.unitQty.length) {
                this.unitQty = lodash.cloneDeep(this.unitQtyOption.items)
            } else {
                this.unitQty = []
            }
        },

        async getPurchaseItemCodes() {
            this.loadingPurchaseItemCode = true

            let filter = { conditions: [] }
                
            try {
                const purchaseItemCodeRes = await this.$http.post(this.purchaseItemCodeOption.endpoint[0] + this.purchaseItemCodeOption.endpoint[1] + '/list-options', { filter })
                if (purchaseItemCodeRes) {
                    this.purchaseItemCodeOption.items = purchaseItemCodeRes.data.rows

                    // let foundFirst = this.purchaseItemCodeOption.items.find((item, index) => index === 0)
                    // this.purchaseItemCode.push(foundFirst)
                    this.loadingPurchaseItemCode = false
                }
            } catch (err) {
                this.loadingPurchaseItemCode = false
                this.$fnError(err)
            }
        },

        setAllPurchaseItemCodes() {
            if (!this.purchaseItemCode.length) {
                this.purchaseItemCode = lodash.cloneDeep(this.purchaseItemCodeOption.items)
            } else {
                this.purchaseItemCode = []
            }
        },

        async getMarketIndeces() {
            this.loadingMarketIndex = true

            let filter = { conditions: [] }
                
            try {
                const marketIndexRes = await this.$http.post(this.marketIndexOption.endpoint[0] + this.marketIndexOption.endpoint[1] + '/list-options-purchase-planning', { id_event: this.event.id, filter })
                if (marketIndexRes) {
                    this.marketIndexOption.items = marketIndexRes.data.rows

                    // let foundFirst = this.marketIndexOption.items.find((item, index) => index === 0)
                    // this.marketIndex.push(foundFirst)
                    this.loadingMarketIndex = false
                }
            } catch (err) {
                this.loadingMarketIndex = false
                this.$fnError(err)
            }
        },

        setAllMarketIndeces() {
            if (!this.marketIndex.length) {
                this.marketIndex = lodash.cloneDeep(this.marketIndexOption.items)
            } else {
                this.marketIndex = []
            }
        },

        clearSelectedStartDate() {
            this.startDate = null
        },

        selectStartDate() {
            this.$refs.menuStart.save(this.startDate)
        },

        clearSelectedEndDate() {
            this.endDate = null
        },

        selectEndDate() {
            this.$refs.menuEnd.save(this.endDate)
        },

        async getFrequencies() {
            this.loadingFrequency = true

            let filter = { conditions: [] }
                
            try {
                const frequencyRes = await this.$http.post(this.frequencyOption.endpoint[0] + this.frequencyOption.endpoint[1] + '/list-options', { filter })
                if (frequencyRes) {
                    this.frequencyOption.items = frequencyRes.data.rows
                    this.loadingFrequency = false
                }
            } catch (err) {
                this.loadingFrequency = false
                this.$fnError(err)
            }
        },

        setAllFrequencies() {
            if (!this.frequency.length) {
                this.frequency = lodash.cloneDeep(this.frequencyOption.items)
            } else {
                this.frequency = []
            }
        },

        createItem() {
            this.selectedRow = {}
            this.itemDialog = true
        },

        editItem() {
            this.selectedRow = this.objectData[this.selectedRowId]
            this.itemDialog = true
        },

        duplicateItem() {
            this.duplicatingItem = true
            this.selectedRow = this.objectData[this.selectedRowId]
            this.itemDialog = true
        },

        closeItemDialog() {
            this.duplicatingItem = false
            this.itemDialog = false
        },

        setDateColumns() {
            this.startingYear = true
            this.year1month =  true
            this.year1quarter =  true
            this.year1semester =  true
            this.laterYear =  true
            this.year2month =  true
            this.year2quarter =  true
            this.year2semester =  true

            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()
            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })
            this.months = _months
            this.year1months = this.months
            this.year2months = this.months
            this.year1quarters = this.quarters
            this.year2quarters = this.quarters
            this.year1semesters = this.semesters
            this.year2semesters = this.semesters
        },

        handleSetAllHiddenColumns() {
            if (this.hasMultipleColumns) {
                this.showPurchaseChannel = false
                this.showSalesChannel = false
                this.showSalesForce = false
                this.showSalesForceBP = false
                this.showCustomerGroup = false
                this.showCostCenter = false
                this.showVendor = false
                this.showPurchaseDescription = false
                this.showProduct = false
                this.showEmployee = false
                this.showPurchaseItemCode = false
                this.showMarketIndex = false
                this.showRenewalMonth = false
                this.showStartDate = false
                this.showEndDate = false
                this.showFrequency = false
                this.showUnitValue = false
                this.showUnitQty = false
                this.showContractQuantity = false
            } else {
                this.showPurchaseChannel = true
                this.showSalesChannel = true
                this.showSalesForce = true
                this.showSalesForceBP = true
                this.showCustomerGroup = true
                this.showCostCenter = true
                this.showVendor = true
                this.showPurchaseDescription = true
                this.showProduct = true
                this.showEmployee = true
                this.showPurchaseItemCode = true
                this.showMarketIndex = true
                this.showRenewalMonth = true
                this.showStartDate = true
                this.showEndDate = true
                this.showFrequency = true
                this.showUnitValue = true
                this.showUnitQty = true
                this.showContractValue = true
                this.showContractQuantity = true
            }
        },

        setHiddenColumns() {
            let _hiddenColumns = { columns: [], copyPasteEnabled: true }

            this.columns.forEach((column, index) => {
                if (column.hide) {
                    _hiddenColumns.columns.push(index)
                }
            })

            this.hiddenColumns = _hiddenColumns

            let cols = []

            for (let i = 1; i <= 12; i++) {
                let col = null
                let foundMonth = this.year1months.find(m => m.id === i)
                
                if (!foundMonth) {
                    if (i < 10) {
                        col = this.columns.findIndex(c => c.data === `value0${i}`)
                    } else {
                        col = this.columns.findIndex(c => c.data === `value${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 13; i <= 24; i++) {
                let col = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth) {
                    col = this.columns.findIndex(c => c.data === `value${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year1quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year2quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year1semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year1semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year2semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null

                if (i === 1) {
                    if (!this.startingYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                } else if (i === 2) {
                    if (!this.laterYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            this.hiddenColumns.columns.push(...cols)
        },

        setDateReadOnlyColumns() {
            const monthInputStart = parseInt((this.yearMonthInputStart).toString().slice(4, 6))
            const difference = monthInputStart - this.fiscalYearStartMonth

            this.readOnlyColumns = []

            // verify if month index is before company fiscal year
            if (difference > 0) {
                for (let i = 1; i <= difference; i++) {
                    let col = null

                    if (i < 10) {
                        col = `value0${i}`
                    } else {
                        col = `value${i}`
                    }

                    let foundColumn = this.columns.find(c => c.data === col)
                    let foundColumnIndex = this.columns.findIndex(c => c.data === col)
                    if (foundColumn) {
                        foundColumn.readOnly = true
                        foundColumn.className += ' read-only-column'
                        this.readOnlyColumns.push(foundColumnIndex)
                    }
                }
            }

            const totalColumns = [
                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
            ]

            // verify if column is readOnly
            this.columns.forEach((c, i) => {
                if (totalColumns.includes(c.data) && c.readOnly){
                    this.readOnlyColumns.push(i)
                }
            })
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }

            this.year1months.length ? this.year1month = true : this.year1month = false
            this.year2months.length ? this.year2month = true : this.year2month = false
            
            this.hotInstance.render()
        },

        showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 4; i++) {
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1quarter) {
                    this.year1quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year1quarters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2quarter) {
                    this.year2quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year2quarters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1quarters
            } else if ( year === 2){
                array = this.year2quarters
            }

            for (let i = 1; i <= 4; i++) {
                let foundQuarter = array.find(quarter => quarter.id === i)
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                if (foundQuarter) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1quarters.length ? this.year1quarter = true : this.year1quarter = false
            this.year2quarters.length ? this.year2quarter = true : this.year2quarter = false
            
            this.hotInstance.render()
        },

        showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 2; i++) {
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1semester) {
                    this.year1semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year1semesters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2semester) {
                    this.year2semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year2semesters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1semesters
            } else if ( year === 2){
                array = this.year2semesters
            }

            for (let i = 1; i <= 2; i++) {
                let foundSemester = array.find(semester => semester.id === i)
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                if (foundSemester) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1semesters.length ? this.year1semester = true : this.year1semester = false
            this.year2semesters.length ? this.year2semester = true : this.year2semester = false
            
            this.hotInstance.render()
        },

        showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = this.hotInstance.propToCol(`year${year}`)

            if (year === 1) {
                if (this.startingYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            } else if (year === 2) {
                if (this.laterYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.hotInstance.render()
        },

        showFormulas() {
            this.isShowingFormulas = !this.isShowingFormulas

            if (this.isShowingFormulas) {
                this.backupColHeaders = lodash.cloneDeep(this.colHeaders)
                this.backupReadOnly = this.readOnly
                this.colHeaders = true
            } else {
                this.colHeaders = this.backupColHeaders
                this.readOnly = this.backupReadOnly
            }

            return this.loadGrid()
        },

        showStyles() {
            this.isShowingStyles = !this.isShowingStyles

            return this.loadGrid()
        },

        async exportSheet(){
            this.exporting = true

            let payload = this.setPayload()
            payload['columnsToRemove'] = this.setColumnsToRemove()

            try {
                const res = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        setColumnsToRemove() {
            let columnsToRemove = []

            // year 1 months
            for (let i = 1; i <= 12; i++) {
                let foundMonth = this.year1months.find(m => m.id === i)

                let index = i < 10 ? `0${i}` : i

                if (!foundMonth) {
                    columnsToRemove.push(`value${index}`)
                }
            }

            // year 2 months
            for (let i = 13; i <= 24; i++) {
                let foundMonth = this.year2months.find(m => m.id === i - 12)

                if (!foundMonth) {
                    columnsToRemove.push(`value${i}`)
                }
            }

            // year 1 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year1quarter${i}`)
                }
            }

            // year 2 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year2quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year2quarter${i}`)
                }
            }

            // year 1 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year1semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year1semester${i}`)
                }
            }

            // year 2 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year2semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year2semester${i}`)
                }
            }

            // years
            if (!this.startingYear) {
                columnsToRemove.push(`year1`)
            }

            if (!this.laterYear) {
                columnsToRemove.push(`year2`)
            }

            return columnsToRemove
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                try {
                    const uploadRes = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        // const massEffectRes = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/mass-effect', { ...uploadRes.data.id })
                        // if (massEffectRes) {
                            this.$toast.success(uploadRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.refresh(false)
                        // }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges && !this.isShowingFormulas) {
                e.preventDefault()
                e.returnValue = ''
            }
        },

        async approvalSelect (option) {
            const payload = {
                id_event: this.selectedEvent,
                id_planning_group: this.selectedPlanningGroup,
                justification: this.justification
            }
            try {
                this.loadingApproval = true
                if (option === 1) {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/approve', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } else {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/disapprove', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
            this.dialogApproval = false
            this.loadingApproval = false
            this.justification = ''
            await this.approvalListLevels()
            this.approvalHistory()
            this.refresh()
        },

        async approvalListLevels () {
            let unapprovedLevels = []
            const payload = {
                id_event: this.selectedEvent,
                id_planning_group: this.selectedPlanningGroup
            }

            if (!payload.id_event || !payload.id_planning_group) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-levels', { ...payload })
                if (res.data.rows.length > 0) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                        level.id_status === 5 || !level.id_status ? unapprovedLevels.push(level) : false
                    })
                    unapprovedLevels.length < 1 ? this.actualLevel = this.levels[this.levels.length - 1] : this.actualLevel = unapprovedLevels[0]
                    this.userGroups.forEach((group) => {
                        if (group.id_group === this.actualLevel.id_group) {
                            this.disableApprove = false
                        }
                    })
                    await this.getlevels()
                } else {
                    this.readOnlyApproval = false
                }
            } catch (err) {
                this.$fnError(err)
            }
        }, 

        async approvalHistory () {
            const payload = {
                id_event: this.selectedEvent,
                id_planning_group: this.selectedPlanningGroup
            }

            if (!payload.id_event || !payload.id_planning_group) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-history', { ...payload })
                if (res) {
                    this.approvals = res.data.rows
                    this.approvals.forEach((approval) => {
                        approval.date ? approval.date = moment(approval.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async listUserGroups () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_user",
                            operator: "=",
                            value: this.getUser.id_user
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipSecurity + 'user-group/list', { ...payload })
                if (res) {
                    this.userGroups = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getlevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_approval_flow_level",
                            operator: "=",
                            value: this.actualLevel.id_approval_flow_level
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'approval-flow-level/list', { ...payload })
                if (res) {
                    if (res.data.rows.length > 0) {
                        res.data.rows[0].change_values === 1 ? this.actualLevel.readOnly = false : this.actualLevel.readOnly = true
                    }
                    if (this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4){
                        this.readOnlyApproval = this.actualLevel.readOnly
                        this.readOnlyApproval === true ? this.$toast.warning('Este nível de aprovação não permite alterações') : false
                    } else {
                        this.levels.length > 0 ? this.readOnlyApproval = true : this.readOnlyApproval = false
                        if (this.readOnlyApproval) {
                            this.$toast.success('Este evento está com todos os níveis aprovados')
                        }
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        solver(column = 0, targetRow = 0, targetValue = 0, changeRow = 0){
            function ruleOf3 (initValChange, currTargetVal, initValTarget){
                if(initValTarget === 0 ) return 0
                return ( initValChange * currTargetVal ) / initValTarget
            }
            // TODO: ver com ademir se é assim que posso me referenciar a table, par apode referenciar as celulas
            // OK! isso aí, Luc.
            const table = this.hotInstance

            let initialValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
            let initialValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

            let calculatedChangeRow = ruleOf3( initialValueFromChangeRow , targetValue , initialValueFromTargetRow )
            if(!calculatedChangeRow) return { interations:0, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }

            table.setDataAtCell(changeRow, column, calculatedChangeRow)

            // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

            let condition = true
            let interations = 0

            while (condition) {
                interations++
                let newValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
                let newValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

                let dif = targetValue - newValueFromTargetRow

                if( Math.abs(dif) <= 0.5 ){
                    condition = false
                    table.setDataAtCell(changeRow, column, initialValueFromChangeRow)
                    return { interations, newValueFromChangeRow, newValueFromTargetRow }
                }
                // TODO: testar o algoritimo e ajustar os incrementos e decrementos
                if( dif > 0 && dif <= 1 ) newValueFromChangeRow = newValueFromChangeRow + 0.1
                if( dif > 1 ) newValueFromChangeRow = newValueFromChangeRow + 1
                if( dif < 0 && dif >= -1 ) newValueFromChangeRow = newValueFromChangeRow - 0.1
                if( dif < -1 ) newValueFromChangeRow = newValueFromChangeRow - 1

                table.setDataAtCell(changeRow, column, newValueFromChangeRow)
                // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

                if(interations > 10000) condition = false
            }
            return { interations, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }
        },
    },
}
</script>

<style scoped>
div.v-tab {
    font-size: 12px;
}

div.v-tab.v-tab--active {
    font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
    font-weight: bold;
    color: var(--primary-color);
}

div.v-tab:hover span {
    font-weight: bold;
    color: black;
}

.v-menu__content {
    max-width: 350px !important;
}
</style>

<style>
.v-dialog__content {
    z-index: 2000 !important;
}
</style>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
