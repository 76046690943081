<template>
    <div id="PurchasePlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGrid.vue'

export default {
    name:'PurchasePlanning',

    components:{
        ZnapHotGrid
    },

    data() {
        return {
            options: [
                {
                    column: 'id_cost_center',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Departamento',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_cost_center_type',
                            operator: '=',
                            value: 3
                        }
                    ]
                },
                {
                    column: 'id_account_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta Gerencial',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipAccount, 'account-group' ]
                },
                {
                    column: 'id_event',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Evento',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipEvent, 'event' ]
                },
                {
                    column: 'id_planning_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Grupo de planejamento',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ]
                },
                {
                    column: 'id_purchase_channel',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Canal de compras',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipPurchase, 'purchase-channel', 'list-options-purchase-planning' ]
                },
                {
                    column: 'id_sales_channel',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Tipo de contrato',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-channel', 'list-options-purchase-planning' ]
                },
                {
                    column: 'id_sales_force',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Gerência executiva',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-purchase-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 6
                        }
                    ]
                },
                {
                    column: 'id_sales_force_bp',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'BP',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-purchase-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 7,
                        }
                    ]
                },
                {
                    column: 'id_customer_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta cliente',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipCustomer, 'customer-group', 'list-options-purchase-planning' ]
                },
                // {
                //     column: 'id_cost_center',
                //     is: 'ComboBox',
                //     items: [],
                //     multiple: false,
                //     label: 'Departamento',
                //     required: false,
                //     filterable: true,
                //     endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ],
                //     conditions: [
                //         {
                //             AndOr: 'AND',
                //             column: 'id_cost_center_type',
                //             operator: '=',
                //             value: 3
                //         }
                //     ]
                // },
                {
                    column: 'id_vendor',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Fornecedor e CNPJ',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipVendor, 'vendor' ]
                },
                {
                    column: 'id_product',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Contrato e código do contrato',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipProduct, 'product', 'list-options-purchase-planning' ]
                },
                {
                    column: 'id_employee',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Nome do colaborador',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipOrganization, 'employee' ]
                },
                {
                    column: 'id_unit_measurement_value',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Unidade de medida do valor',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipUnit, 'unit-measurement', 'list-options-purchase-planning']
                },
                {
                    column: 'id_unit_measurement_qty',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Unidade de medida da quantidade',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipUnit, 'unit-measurement', 'list-options-purchase-planning']
                },
                {
                    column: 'purchase_item_code',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Descrição e ID dos itens',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipPurchasePlanning, 'purchase-item' ]
                },
                {
                    column: 'id_market_index',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Índice de reajuste',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipUnit, 'market-index' ]
                },
                {
                    column: 'renewal_month',
                    is: 'ComboBox',
                    items: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ],
                    multiple: false,
                    label: 'Mês de renovação',
                    required: false,
                    isDropdownOption: true,
                    filterable: true,
                },
                {
                    column: 'start_date',
                    is: 'ComboBox',
                    multiple: false,
                    label: 'Início do contrato',
                    required: false,
                    filterable: true,
                },
                {
                    column: 'end_date',
                    is: 'ComboBox',
                    multiple: false,
                    label: 'Término do contrato',
                    required: false,
                    filterable: true,
                },
                {
                    column: 'id_frequency',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Vigência do contrato',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipUnit, 'frequency' ]
                },
            ]

        }
    },
}
</script>

<style scoped>
</style>